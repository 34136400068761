<!-- 自动登录页，根据unionId检测用户是否注册，若已注册，自动登录，若未注册，跳转注册登录页面 -->
<template>
  <div v-if="showPage" class="container">
    <img class="bgimg" src="@/assets/image/tou.png" />
    <div class="content">
      <div class="cbanner">
        <img class="bgimg" src="@/assets/image/kapian.png" />
        <div class="rulename">{{ $payInfo.ruleName || "" }}</div>
        <div class="menthname">{{ $payInfo.parkinglotName || "" }}</div>
      </div>
      <ul class="clist">
        <li>
          <label for="">办理月数</label>
          <div>{{ $payInfo.monthlyNumber || 0 }}个月</div>
        </li>
        <li>
          <label for="">生效日期</label>
          <div>{{ $payInfo.startDate || "" }}</div>
        </li>
        <li>
          <label for="">截止日期</label>
          <div>{{ $payInfo.endDate || "" }}</div>
        </li>
      </ul>
      <div class="allprice">￥{{ $payInfo.amountReceivable || 0.0 }}</div>
      <div class="paybtn" @click="getXpxPay">确认支付</div>
    </div>

    <div class="aliH5PayBody" id="aliH5PayBody"></div>
  </div>
</template>


<script>
import {
  xpxPay,
  getMonthlyPayInfo,
  oauthInvokeV2
} from "@/utils/api";
import { Notify, Toast } from "vant";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      showPage: false,
      $price: "",
      $monthlyId: "",
      $payInfo: {},
      $isExt: 0, //是否续费 1续费 0新办
      $sceneType: "",
      startDate: "",
      endDate: "",
      monthlyNum: "",
      $isWeiXin: 1,
      $aliH5PayBody: "",
      $openId: "",
      $code: "",
      scanAuthInfo: null
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    var obj = this.$route.query;
    this.$monthlyId = obj.m;
    this.$isExt = obj.e;

    // const oScript = document.createElement('script');
    // oScript.type = 'text/javascript';
    // oScript.src = 'https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.inc.min.js';
    // document.body.appendChild(oScript);

    this.isIosorAndroid();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    isIosorAndroid() {
      var ua = window.navigator.userAgent;
      //判断是不是微信
      if (ua.indexOf("MicroMessenger") > 0) {
        this.scanAuthInfo = JSON.parse(localStorage.getItem('scanAuthInfo'))
        this.$openId = this.scanAuthInfo.openid
        if (this.$isExt == 0) {//是否续费 1续费 0新办
          this.$sceneType = "102H7001";
        } else {
          this.$sceneType = "102H7002";
        }
        this.$isWeiXin = 1;
        //return "WeiXIN";
      }
      //判断是不是支付宝
      if (ua.indexOf("AlipayClient") > 0) {
        if (this.$isExt == 0) {
          this.$sceneType = "103H7001";
        } else {
          this.$sceneType = "103H7002";
        }
        this.$isWeiXin = 2;
        //return "Alipay";
      }
      if (this.$isWeiXin !== 1 && this.$isWeiXin !== 2) {
        Toast("请使用微信或者支付宝扫码支付");
      } else {
        this.getPayInfo();
      }
    },
    getPayInfo() {
      var that = this;
      that.showPage = false;
      getMonthlyPayInfo({ monthlyId: this.$monthlyId })
        .then(result => {
          if (result.status == "200" || result.status == 200) {
            that.$payInfo = result.data;
            that.showPage = true;
          } else {
            Toast(result.message);
          }
        })
        .catch(err => {
          Toast("网络错误，请稍后再试");
        });
    },
    getXpxPay() {
      var that = this;
      var param = {
        sceneType: that.$sceneType,
        parkingLotId: that.$payInfo.parkinglotId,
        amountCurrentPay: that.$payInfo.amountReceivable,
        dataId: that.$payInfo.monthlyId,
        incomeName: that.$payInfo.ruleName,
        isExt: that.$isExt,
        endDate: that.$payInfo.endDate,
        startDate: that.$payInfo.startDate,
        openId: that.$openId
      };
      xpxPay(param)
        .then(result => {
          //alert(JSON.stringify(res));
          if (result.status == "200" || result.status == 200) {
            if (that.$isWeiXin == 1) {
              that.wechatPayAction(result.data);
            } else if (that.$isWeiXin == 2) {
              // that.alipayStip(result.data.aliH5PayBody);
              that.aliPayAction(result.data);
            }
          } else {
            Toast(result.message);
          }
        })
        .catch(err => {
          //Toast("网络错误，请稍后再试");
        });
    },
    // 支付宝支付
    aliPayAction(data){
      window.location.href = data.payUrl
    },
    alipayStip(formPage) {
      var aliH5PayBody = decodeURI(formPage);
      var doc = document.open("text/html", "replace");
      doc.write(aliH5PayBody);
      doc.close();
    },
    //微信支付
    wechatPayAction(obj) {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            this.onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
        }
      } else {
        this.onBridgeReady(obj);
      }
    },
    onBridgeReady(obj) {
      Toast.clear();
      let that = this;
      console.log(obj)
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: obj.jsApiObject.appId, //公众号名称，由商户传入
          timeStamp: obj.jsApiObject.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: obj.jsApiObject.nonceStr, //随机串
          package: obj.jsApiObject.package,
          signType: obj.jsApiObject.signType, //微信签名方式：
          paySign: obj.jsApiObject.paySign //微信签名
        },
        function(res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            that.$router.push({
              path: "/mrpaysuc",
              query: {
                p: that.$payInfo.amountReceivable
              }
            });
          } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
            window.console.log("用户取消支付");
            Toast("已取消支付");
          } else {
            Notify({ type: "danger", message: res.err_msg });
          }
        }
      );
    }
  },
  destroyed() {}
};
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.container {
  position: relative;
  .bgimg {
    width: 100%;
  }
  .aliH5PayBody {
    position: absolute;
    z-index: 1;
    margin-top: 60px;
    border: 1px solid red;
  }
  .content {
    position: absolute;
    z-index: 1;
    top: 60px;
    padding: 0 48px;
    .clist {
      font-size: 28px;
      background: #fff;
      box-shadow: 0px 8px 40px 0px rgba(174, 185, 190, 0.7);
      border-radius: 20px;
      padding: 20px;
      margin-top: 60px;
      li {
        position: relative;
        height: 100px;
        line-height: 100px;
        border-bottom: 1px solid #eee;
        text-align: left;
        div {
          position: absolute;
          right: 0;
          top: 0;
          color: #666666;
        }
      }
      li:last-child {
        border-bottom: 0;
      }
    }
    .allprice {
      font-size: 60px;
      font-weight: bold;
      color: #fd7732;
      text-align: center;
      margin-top: 60px;
    }
    .paybtn {
      margin-top: 60px;
      background: linear-gradient(91deg, #8feac6 0%, #25a0a5 100%);
      border-radius: 90px;
      color: #fff;
      text-align: center;
      font-size: 40px;
      height: 90px;
      line-height: 90px;
    }
  }
  .cbanner {
    height: 400px;
    .bgimg {
      height: 100%;
    }
    .rulename {
      position: absolute;
      top: 120px;
      font-size: 28px;
      color: #fff;
      left: 68px;
    }
    .menthname {
      position: absolute;
      top: 320px;
      font-size: 28px;
      color: #fff;
      text-align: right;
      right: 68px;
    }
  }
}
</style>